import React, { Suspense, lazy, useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./App.css";
import { jwtDecode } from "jwt-decode";
import PublicLayout from "./pages/layouts/PublicLayout";
import Landing from "./pages/landing/Landing";
import { checkAdmin, tokenLoader, checkStudent } from "./services/auth";

const Courses = lazy(() => import("./pages/courses/Courses"));
const NewCourse = lazy(() => import("./pages/courses/NewCourse"));
const ContactUs = lazy(() => import("./pages/contactus/ContactUs"));
const Pricing = lazy(() => import("./pages/pricing/Pricing"));
const PageNotFound = lazy(() => import("./pages/error/PageNotFound"));
const LogIn = lazy(() => import("./pages/login/LogIn"));
const AboutMe = lazy(() => import("./pages/about-me/AboutMe"));
const NewUser = lazy(() => import("./pages/users/NewUser"));

const AdminLayout = lazy(() => import("./pages/layouts/AdminLayout"));
const StudentLayout = lazy(() => import("./pages/layouts/StudentLayout"));
const StudentEnrollment = lazy(() => import("./pages/users/StudentEnrollment"));
const StudentProfile = lazy(() => import("./pages/users/StudentProfile"));

function App() {
  const [currentUser, setCurrentUser] = useState({
    language: "english",
    firstName: "unknown",
    lastName: "unknown",
    userId: null,
    isLoggedIn: false,
    role: "public",
    avatarUrl: null,
    count: 0,
    wishlist: [],
    activeClass: null,
    activePlan: [],
    coursesData: {
      spanish: [],
      english: [],
      translation: {
        spanish: {},
        english: {},
      },
    },
    plansData: {
      spanish: [],
      english: [],
    },
  });

  const [sidebarUpdateCounter, setSidebarUpdateCounter] = useState(0);

  useEffect(() => {
    const ifToken = localStorage.getItem("tutorMioToken");
    if (ifToken && !currentUser.isLoggedIn) {
      const decodedToken = jwtDecode(ifToken);
      const currentTime = Date.now() / 1000;
      decodedToken.exp > currentTime
        ? logInUser(
            decodedToken.role,
            decodedToken.unique_name[0],
            decodedToken.unique_name[1],
            parseInt(decodedToken.nameid),
            decodedToken.avatarUrl
          )
        : localStorage.removeItem("tutorMioToken");
    }
  }, [currentUser.isLoggedIn]);

  function logInUser(role, fName, lName, id, avatarUrl) {
    setCurrentUser((prevUser) => {
      let newUser = { ...prevUser };
      newUser.isLoggedIn = true;
      newUser.firstName =
        String(fName).charAt(0).toUpperCase() + String(fName).slice(1);
      newUser.lastName =
        String(lName).charAt(0).toUpperCase() + String(lName).slice(1);
      newUser.role = role;
      newUser.userId = id;
      newUser.avatarUrl = avatarUrl;
      return newUser;
    });
  }

  function logOutUser() {
    localStorage.removeItem("tutorMioToken");
    setCurrentUser((prevUser) => {
      let newUser = { ...prevUser };
      newUser.isLoggedIn = false;
      newUser.firstName = "unknown";
      newUser.lastName = "unknown";
      newUser.role = "public";
      newUser.userId = null;
      return newUser;
    });
  }

  function onClassRequested(data) {
    if (currentUser.wishlist.length === 0 && typeof data === "object") {
      wishlistPusher(data);
    } else if (currentUser.wishlist.length !== 0) {
      const incomingTitle = data.title;
      const duplicate = currentUser.wishlist.find(wishlistFinder);
      function wishlistFinder(item) {
        return item.title === incomingTitle;
      }
      duplicate ? console.log("duplicate class found") : wishlistPusher(data);
    }
  }

  function onRemoveFromWishlist(idx, qty, clearAll) {
    setCurrentUser((prevState) => {
      let newState = { ...prevState };
      newState.wishlist = [...prevState.wishlist];
      newState.wishlist.splice(idx, qty);
      if (clearAll === "clear-all") {
        newState.activePlan = [];
      }
      return newState;
    });
  }

  function wishlistPusher(courseClass) {
    setCurrentUser((prevState) => {
      let newState = { ...prevState };
      newState.wishlist = [...prevState.wishlist];
      newState.wishlist.push(courseClass);
      return newState;
    });
  }

  function setActiveClass(activeClass) {
    setCurrentUser((prevState) => {
      let newState = { ...prevState };
      newState.activeClass = activeClass;
      return newState;
    });
  }
  function onPlanChosen(data) {
    setCurrentUser((prevState) => {
      let newState = { ...prevState };
      newState.activePlan = [data];
      return newState;
    });
  }
  function onRemovePlan() {
    setCurrentUser((prevState) => {
      let newState = { ...prevState };
      newState.activePlan = [];
      return newState;
    });
  }

  function onRemovePlanFromWishlist() {
    setCurrentUser((prevState) => {
      let newState = { ...prevState };
      newState.activePlan = [];
      return newState;
    });
  }

  function setCourses(courses) {
    setCurrentUser((prevState) => {
      let newState = { ...prevState };
      newState.coursesData = { ...newState.coursesData };
      newState.coursesData[prevState.language] = courses;
      return newState;
    });
  }

  function setCourseTranslation(trans, lang) {
    setCurrentUser((prevState) => {
      let newState = { ...prevState };
      newState.coursesData = { ...newState.coursesData };
      newState.coursesData.translation[lang] = trans;
      return newState;
    });
  }

  function onGetPlans(plans) {
    setCurrentUser((prevState) => {
      let newState = { ...prevState };
      newState.plansData = { ...newState.plansData };
      newState.plansData[prevState.language] = plans;
      return newState;
    });
  }

  function onLanguageChange(language) {
    setCurrentUser((prevState) => {
      let newState = { ...prevState };
      newState.language = language;
      return newState;
    });
  }
  //#region route common props
  const commonCoursesProps = {
    notifyApp: onClassRequested,
    activeClass: currentUser.activeClass,
    wishlist: currentUser.wishlist,
    onDisactivateCurrentClass: setActiveClass,
    notifyAppRemove: onRemoveFromWishlist,
    courses: setCourses,
    tunnelingCourses: currentUser.coursesData,
    language: currentUser.language,
    bubblingCourseTranslation: setCourseTranslation,
    tunnelingCourseTranslation: currentUser.coursesData.translation,
  };
  const commonPricingProps = {
    onPlanChosen: onPlanChosen,
    confirmedPlan: currentUser.activePlan,
    onRemovePlan: onRemovePlan,
    bubblingPlans: onGetPlans,
    tunnelingPlans: currentUser.plansData,
    language: currentUser.language,
  };
  const commonLoginProps = {
    logOutUser: logOutUser,
    isLoggedIn: currentUser.isLoggedIn,
    userRole: currentUser.role,
    logInUser: logInUser,
  };
  const commonContactProps = {
    activePlan: currentUser.activePlan,
    requestedClasses: currentUser.wishlist,
    language: currentUser.language,
  };
  //#endregion route common props END

  function updateSidebar() {
    setSidebarUpdateCounter((sidebarUpdateCounter) => {
      return sidebarUpdateCounter + 1;
    });
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <PublicLayout
          user={currentUser}
          navigateToClass={setActiveClass}
          clearWishList={onRemoveFromWishlist}
          removeClass={onRemoveFromWishlist}
          removePlan={onRemovePlanFromWishlist}
          bubblingLang={onLanguageChange}
          logOutUser={logOutUser}
        />
      ),
      errorElement: <PageNotFound />,
      children: [
        { index: true, element: <Landing language={currentUser.language} /> },
        { path: "courses", element: <Courses {...commonCoursesProps} /> },
        { path: "contact", element: <ContactUs {...commonContactProps} /> },
        { path: "plans", element: <Pricing {...commonPricingProps} /> },
        { path: "login", element: <LogIn {...commonLoginProps} /> },
        { path: "about", element: <AboutMe /> },
      ],
    },
    //admin layout
    {
      path: "/admin",
      element: (
        <AdminLayout
          user={currentUser}
          navigateToClass={setActiveClass}
          clearWishList={onRemoveFromWishlist}
          removeClass={onRemoveFromWishlist}
          removePlan={onRemovePlanFromWishlist}
          bubblingLang={onLanguageChange}
          logOutUser={logOutUser}
          updateSidebar={sidebarUpdateCounter}
        />
      ),
      id: "admin",
      loader: tokenLoader,
      errorElement: <PageNotFound />,
      children: [
        {
          index: true,
          element: <Landing language={currentUser.language} />,
          loader: checkAdmin,
        },
        {
          path: "courses",
          element: <Courses {...commonCoursesProps} />,
          loader: checkAdmin,
        },
        {
          path: "courses/new",
          element: <NewCourse />,
          loader: checkAdmin,
        },
        {
          path: "courses/new/:courseId",
          element: <NewCourse />,
          loader: checkAdmin,
        },
        {
          path: "plans",
          element: <Pricing {...commonPricingProps} />,
          loader: checkAdmin,
        },
        {
          path: "contact",
          element: <ContactUs {...commonContactProps} />,
          loader: checkAdmin,
        },
        {
          path: "users/new",
          element: <NewUser updateSidebar={updateSidebar} />,
          loader: checkAdmin,
        },
        {
          path: "users/new/:studentId",
          element: <NewUser />,
          loader: checkAdmin,
        },
        {
          path: "users/:id/enroll",
          element: <StudentEnrollment />,
          loader: checkAdmin,
        },
      ],
    },
    //student layout
    {
      path: "student",
      element: (
        <StudentLayout
          user={currentUser}
          navigateToClass={setActiveClass}
          clearWishList={onRemoveFromWishlist}
          removeClass={onRemoveFromWishlist}
          removePlan={onRemovePlanFromWishlist}
          bubblingLang={onLanguageChange}
          logOutUser={logOutUser}
        />
      ),
      id: "student",
      loader: tokenLoader,
      errorElement: <PageNotFound />,
      children: [
        {
          index: true,
          element: <Landing language={currentUser.language} />,
        },
        {
          path: "courses",
          element: <Courses {...commonCoursesProps} />,
        },

        {
          path: "plans",
          element: <Pricing {...commonPricingProps} />,
        },
        {
          path: "contact",
          element: <ContactUs {...commonContactProps} />,
        },
        {
          path: "profile",
          element: <StudentProfile />,
          loader: checkStudent,
        },
      ],
    },
  ]);

  return (
    <Suspense fallback={<p style={{ position: "absolute" }}>loading...</p>}>
      <RouterProvider router={router} future={{ v7_startTransition: true }} />
    </Suspense>
  );
}

export default App;
